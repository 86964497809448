import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import type { PlayerMapStat } from "@/data-models/csgo-player-map-stats.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function useCsgoMaps({
  gameModeId,
  profileId,
  type,
}: {
  gameModeId: string;
  profileId: string;
  type?: string;
}) {
  const state = useSnapshot(readState);
  // TODO: Solve how to properly type the after transform model here with the aliased key
  const mapStats: { [mapCode: string]: PlayerMapStat & { map: string } } =
    state.csgo.playerMapStats[profileId]?.[gameModeId];
  const allMapStats = useMemo(
    () => (mapStats ? Object.values(mapStats) : []),
    [mapStats],
  );
  const maps = useMemo(() => {
    if (!mapStats || mapStats instanceof Error) return [];
    const totalMatches = allMapStats.reduce(
      (acc, m) => acc + m.matchWins + m.matchLost + m.matchDraw,
      0,
    );
    return orderArrayBy(
      allMapStats.map((map) => {
        const totalMapRounds =
          map.terroristRoundsPlayed + map.counterTerroristRoundsPlayed;
        const matches = map.matchWins + map.matchLost + map.matchDraw;
        const result = {
          ...map,
          matches,
          winRate:
            (map.counterTerroristRoundsWon + map.terroristRoundsWon) /
            totalMapRounds,
          playRate: matches / totalMatches,
          ctWinrate:
            map.counterTerroristRoundsWon / map.counterTerroristRoundsPlayed,
          tWinrate: map.terroristRoundsWon / map.terroristRoundsPlayed,
        };
        for (const key in result) {
          if (key === "map") continue;
          result[key] = sanitizeNumber(result[key]);
        }
        return result;
      }),
      type ?? "matchWins",
      "desc",
    );
  }, [mapStats, allMapStats, type]);
  return {
    maps,
  };
}

export default useCsgoMaps;
