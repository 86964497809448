import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import {
  ListContainer,
  MapIcon,
  MapIconOutline,
  RowContainer,
} from "@/game-csgo/Common.style.jsx";
import { MAPS } from "@/game-csgo/constants.mjs";
import csgoStatic from "@/game-csgo/static.mjs";
import useCsgoMaps from "@/game-csgo/useCsgoMaps.jsx";
import { getWinRateColor } from "@/game-csgo/utils.mjs";
import ProfileShowMore from "@/shared/ProfileShowMore.jsx";
import { StatLeft, StatRight } from "@/shared/ProfileStats.style.jsx";
import { formatToPercent } from "@/util/i18n-helper.mjs";

const DEFAULT_SHOWN = 4;

interface Props {
  name: keyof typeof MAPS;
  winRate?: number;
  ctWinRate: number;
  tWinRate: number;
}

const MapRow = ({ name, winRate, ctWinRate, tWinRate }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const map = MAPS[name];

  return (
    <RowContainer>
      <MapIconOutline>
        <MapIcon src={csgoStatic.getMapImage(name)} />
      </MapIconOutline>
      <StatLeft>
        <div className="type-caption">{t(map?.i18nKey, map?.name)}</div>
        {winRate ? (
          <div className="flex gap-sp-1">
            <span
              className="type-caption--bold"
              style={{ color: getWinRateColor(winRate) }}
            >
              {t("common:percentWinrate", "{{winRate, percent}} Win Rate", {
                winRate,
              })}
            </span>
          </div>
        ) : null}
      </StatLeft>
      <StatRight>
        <div className="type-caption--bold flex flex-end gap-sp-1">
          <span>{`CT`}</span>
          <span
            style={{
              color: getWinRateColor(ctWinRate),
            }}
          >
            {formatToPercent(language, ctWinRate, { max: 0 })}
          </span>
        </div>
        <div className="type-caption--bold flex flex-end gap-sp-1">
          <span>{`T`}</span>
          <span style={{ color: getWinRateColor(tWinRate) }}>
            {formatToPercent(language, tWinRate, { max: 0 })}
          </span>
        </div>
      </StatRight>
    </RowContainer>
  );
};

const MapStats = ({ profileId, gameModeId }) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();
  const setShowMore = () => setShowAll((prev) => !prev);

  const { maps: mapsAll } = useCsgoMaps({
    profileId,
    gameModeId,
  });

  const maps = useMemo(
    () => (showAll ? mapsAll : mapsAll.slice(0, DEFAULT_SHOWN)),
    [showAll, mapsAll],
  );

  return maps.length ? (
    <Card
      padding="0"
      title={t("common:navigation.mapStatistics", "Map Statistics")}
    >
      <ListContainer>
        {maps.map((map) => (
          <MapRow
            key={map.map}
            name={map.map as keyof typeof MAPS}
            winRate={map.winRate}
            ctWinRate={map.ctWinrate}
            tWinRate={map.tWinrate}
          />
        ))}
      </ListContainer>
      {maps.length > DEFAULT_SHOWN && (
        <div style={{ padding: "0 var(--sp-6)" }}>
          <ProfileShowMore showAll={showAll} setShowMore={setShowMore} />
        </div>
      )}
    </Card>
  ) : null;
};

export default React.memo(MapStats);
