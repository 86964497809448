import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import type {
  PlayerWeaponStats,
  PlayerWeaponStatsAggregate,
} from "@/data-models/csgo-player-weapon-stats.mjs";
import { WEAPONS } from "@/game-csgo/constants.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function useCsgoWeapons({
  gameModeId,
  profileId,
}: {
  gameModeId: string;
  profileId: string;
}) {
  const state = useSnapshot(readState);
  const weaponStats: PlayerWeaponStatsAggregate =
    state.csgo.playerWeaponStats[profileId]?.[gameModeId];
  const weapons: PlayerWeaponStats[] = useMemo(() => {
    return weaponStats
      ? orderArrayBy(
          Object.values(weaponStats).filter(
            ({ weaponId }) => WEAPONS[weaponId],
          ),
          "kills",
          "desc",
        )
      : [];
  }, [weaponStats]);

  return {
    weapons,
  };
}

export default useCsgoWeapons;
