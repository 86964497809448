import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { ListContainer, RowContainer } from "@/game-csgo/Common.style.jsx";
import { WEAPONS } from "@/game-csgo/constants.mjs";
import useCsgoWeapons from "@/game-csgo/useCsgoWeapons.jsx";
import { getHeadshotColor, getWeaponIcon } from "@/game-csgo/utils.mjs";
import ProfileShowMore from "@/shared/ProfileShowMore.jsx";
import { StatLeft, StatRight } from "@/shared/ProfileStats.style.jsx";

const DEFAULT_SHOWN = 4;

const WeaponRow = ({ weaponId, averageDamage, kills, headshotPercentage }) => {
  const { t } = useTranslation();

  const weapon = WEAPONS[weaponId];

  return (
    <RowContainer>
      <img src={getWeaponIcon(weapon?.icon)} width="70px" />
      <StatLeft>
        <div className="type-caption" style={{ color: "var(--shade0)" }}>
          {t(weapon?.i18nKey, weapon?.name)}
        </div>
        {typeof averageDamage === "number" ? (
          <div className="type-caption flex gap-sp-1">
            {t("common:stats.avgDmgValue", "{{ value, number }} Avg. Dmg.", {
              value: averageDamage,
            })}
          </div>
        ) : null}
      </StatLeft>
      <StatRight>
        <div
          className="type-caption--bold flex flex-end gap-sp-1"
          style={{ color: "var(--shade0)" }}
        >
          {t("common:stats.killsValue", "{{ value, number }} Kills", {
            value: kills,
          })}
        </div>
        {typeof headshotPercentage === "number" ? (
          <div className="type-caption--bold flex flex-end gap-sp-1">
            <span
              style={{
                color: getHeadshotColor(headshotPercentage * 100),
              }}
            >
              {t("common:stats.valueHsPercent", "{{hsPercent, percent}} HS", {
                hsPercent: headshotPercentage,
                formatParams: {
                  maximumFractionDigits: 0,
                },
              })}
            </span>
          </div>
        ) : null}
      </StatRight>
    </RowContainer>
  );
};

const WeaponStats = ({ profileId, gameModeId }) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  const setShowMore = () => setShowAll((prev) => !prev);

  const { weapons } = useCsgoWeapons({
    profileId,
    gameModeId,
  });

  const visibleWeapons = useMemo(
    () => (showAll ? weapons : weapons.slice(0, DEFAULT_SHOWN)),
    [showAll, weapons],
  );

  return visibleWeapons.length ? (
    <Card padding="0" title={t("common:navigation.weapons", "Weapons")}>
      <ListContainer>
        {visibleWeapons.map((weapon) => (
          <WeaponRow
            key={weapon.weaponId}
            weaponId={weapon.weaponId}
            averageDamage={weapon.averageDamage}
            kills={weapon.kills}
            headshotPercentage={weapon.headshotPercentage}
          />
        ))}
      </ListContainer>
      {weapons.length > DEFAULT_SHOWN ? (
        <div style={{ padding: "0 var(--sp-6)" }}>
          <ProfileShowMore showAll={showAll} setShowMore={setShowMore} />
        </div>
      ) : null}
    </Card>
  ) : null;
};

export default React.memo(WeaponStats);
