import React from "react";
import { useTranslation } from "react-i18next";

import { MAPS as DATABASE_MAPS } from "@/game-cs2-marketing/constants.mjs";
import { MapIcon, MapIconOutline } from "@/game-csgo/Common.style.jsx";
import { MAPS } from "@/game-csgo/constants.mjs";
import csgoStatic from "@/game-csgo/static.mjs";
import useCsgoMaps from "@/game-csgo/useCsgoMaps.jsx";
import { getGameModeById } from "@/game-csgo/utils.mjs";
import DataTable from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import { formatToFixedNumber, formatToPercent } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

const MapsTable = () => {
  const { t } = useTranslation();
  const {
    parameters: [profileId],
    searchParams,
  } = useRoute();
  const sortBy = searchParams.get("sortBy") || "winrate";
  const gameModeParam = searchParams.get("mode");
  const gameModeId = getGameModeById(gameModeParam).id;
  const {
    i18n: { language },
  } = useTranslation();

  const { maps } = useCsgoMaps({
    profileId,
    gameModeId,
    type: sortBy,
  });

  const cols = [
    {
      display: t("common:map", "Map"),
      align: "left",
      primary: true,
    },
    { display: t("common:winrate", "Winrate"), align: "center", isStat: true },
    {
      display: t("csgo:ctWinrate", "CT Winrate"),
      align: "center",
      isStat: true,
    },
    { display: t("csgo:tWinrate", "T Winrate"), align: "center", isStat: true },
    {
      display: t("common:playRate", "Play Rate"),
      align: "center",
      isStat: true,
    },
    { display: t("common:matches", "Matches"), align: "center", isStat: true },
  ];

  const rows = maps
    .filter((m) => !!MAPS[m.map])
    .map((m) => {
      const { name: mapName } = MAPS[m.map];
      const mapImgUrl = csgoStatic.getMapImage(m.map);
      return [
        {
          display: (
            <>
              <MapIconOutline style={{ marginRight: "var(--sp-4)" }}>
                <MapIcon src={mapImgUrl} />
              </MapIconOutline>
              {mapName}
            </>
          ),
          value: mapName,
          link: Object.keys(DATABASE_MAPS).includes(m.map)
            ? `/cs2/database/maps/${m.map}/overview`
            : null,
        },
        {
          display: formatToPercent(language, m.winRate),
          value: m.winRate,
        },
        {
          display: formatToPercent(language, m.ctWinrate),
          value: m.ctWinrate,
        },
        {
          display: formatToPercent(language, m.tWinrate),
          value: m.tWinrate,
        },
        {
          display: formatToPercent(language, m.playRate),
          value: m.playRate,
        },
        {
          display: formatToFixedNumber(language, m.matches),
          value: m.matches,
        },
      ];
    });

  return (
    <PageContainer>
      <DataTable
        cols={cols}
        rows={rows}
        sortDir="DESC"
        sortCol={1}
        sortColTiebreak={2}
        indexCol
        noResultsDescription={[
          "csgo:weapons.noMapStatsFound",
          "There are currently no statistics available for the maps page.",
        ]}
        noResultsReturnLink={`/cs2/profile/${profileId}`}
        noResultsReturnText={["common:goBackToOverview", "Go Back to Overview"]}
      />
    </PageContainer>
  );
};

export default MapsTable;
