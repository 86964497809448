import React from "react";
import { useTranslation } from "react-i18next";

import { MAPS } from "@/game-csgo/constants.mjs";
import csgoStatic from "@/game-csgo/static.mjs";
import {
  getGameModeByMatch,
  getLastRound,
  getMatchResultLabel,
  getMatchStats,
  getPlayerPlacement,
  getRoundsLength,
  getSortedPlayersByLastRound,
  getWonLost,
} from "@/game-csgo/utils.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import globals from "@/util/global-whitelist.mjs";
import { calcRate } from "@/util/helpers.mjs";
import {
  formatToFixedNumber,
  formatToPercent,
  getLocale,
} from "@/util/i18n-helper.mjs";

// TODO: Implement expand tabs
const Tabs = {};

function MatchTileContent({ match, profileId }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const gameMode = getGameModeByMatch(match);
  const lastRound = getLastRound(match);
  const players = getSortedPlayersByLastRound(lastRound?.playerMatchRoundStats);
  const playerIdx = players.findIndex((p) => p.steamId === profileId);
  const player = players[playerIdx];

  const wonLost = getWonLost({ player, round: lastRound });
  const placement = getPlayerPlacement({
    match,
    wonLost,
    rank: playerIdx + 1,
  });
  const { label, win } = getMatchResultLabel(placement, match, t);

  const map = MAPS[match.map];
  const { kda, dpr, kpr } = getMatchStats(
    player,
    getRoundsLength(match.rounds),
  );

  const stats = [
    {
      top: kda.toLocaleString(getLocale(), {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }),
      pill: null,
      bottom: t("common:stats.kda", "KDA"),
    },
    {
      top: formatToFixedNumber(language, player?.score, 0),
      pill: null,
      bottom: t("common:stats.score", "Score"),
    },
    {
      top: formatToPercent(
        getLocale(),
        calcRate(player?.hsKills, player?.kills, { max: 1 }),
      ),
      pill: null,
      bottom: t("common:stats.hsPercent", "HS %"),
    },
    gameMode?.rounds && {
      top: t("common:stats.kprValue", "{{value, number}} KPR", {
        value: kpr,
        formatParams: {
          maximumFractionDigits: 1,
        },
      }),
      pill: null,
      bottom: t("common:stats.kpr", "KPR"),
    },
    gameMode?.rounds && {
      top: dpr.toLocaleString(getLocale(), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      pill: null,
      bottom: t("common:stats.dpr", "DPR"),
    },
  ];

  return (
    <MatchTileExpand
      image={{
        src: csgoStatic.getMapImage(match.map),
        alt: "",
      }}
      isWin={win}
      tabDefault={""}
      tabsToElement={Tabs}
      tabsLabel={{}}
      tabArgs={{
        match,
        profileId,
      }}
      stats={stats}
      urlWeb={`${globals.location.origin}/cs2/match/${profileId}/${match.matchId}`}
      urlCanonical={`/cs2/match/${profileId}/${match.matchId}`}
      titles={[
        {
          text: label,
          bold: true,
          color: win ? "var(--turq)" : "var(--red)",
        },
        gameMode && {
          text: t(gameMode.t, gameMode.label),
        },
        map?.name && {
          text: map.name,
        },
      ]}
      matchDate={match.gameStartedAt}
      aside={null}
    />
  );
}

export default React.memo(MatchTileContent);
