import React, { useMemo } from "react";
import { Select } from "clutch/src/Select/Select.jsx";

import { weaponCategories } from "@/game-csgo/constants.mjs";

const FilterWeaponCategory = ({ onChange, selected }) => {
  const options = useMemo(
    () =>
      weaponCategories.map(({ value, label }) => ({
        value,
        text: label,
      })),
    [],
  );

  return <Select onChange={onChange} selected={selected} options={options} />;
};

export default FilterWeaponCategory;
