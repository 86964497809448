import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

const Row2 = styled("div")<{ $padding: string }>`
  display: flex;
  align-items: center;
  padding: ${(props) =>
    props.$padding ? props.$padding : "0 0 var(--sp-6) 0"};
`;

interface Props {
  padding?: string;
  showAll?: boolean;
  setShowMore?: () => void;
}

const Footer = ({ showAll, setShowMore, padding }: Props) => {
  const { t } = useTranslation();

  return (
    <Row2 $padding={padding}>
      <Button block onClick={setShowMore}>
        {showAll
          ? t("common:showLess", "Show less")
          : t("common:showMore", "Show more")}
      </Button>
    </Row2>
  );
};

export default Footer;
