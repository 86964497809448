import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";
import { t } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import getCsgoColor from "@/game-csgo/colors.mjs";
import {
  DEFAULT_MATCHES_LIMIT,
  GAME_MODE_RANKS,
  GAME_MODES,
  RANKS,
} from "@/game-csgo/constants.mjs";
import MapStats from "@/game-csgo/MapStats.jsx";
import MatchHistoryHeader from "@/game-csgo/MatchHistoryHeader.jsx";
import MatchTileContent from "@/game-csgo/MatchTileContent.jsx";
import useCsLastN from "@/game-csgo/useCsLastN.jsx";
import { getGameModeById, getRankIcon } from "@/game-csgo/utils.mjs";
import WeaponStats from "@/game-csgo/WeaponStats.jsx";
import CsgoUnranked from "@/inline-assets/csgo-unranked.svg";
import SkeletonMatchIcon from "@/inline-assets/skeleton-match.svg";
import { MatchList, MatchTile, ShowMoreFooter } from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import ProfileRank from "@/shared/ProfileRank.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function ProfileOverview() {
  const {
    parameters: [steamId],
    searchParams,
    currentPath,
  } = useRoute();

  const {
    csgo: { matchlists, matches },
  } = useSnapshot(readState);
  const gameModeId = getGameModeById(searchParams.get("mode")).id;
  const matchlist = useMemo(
    () => matchlists?.[steamId]?.[gameModeId] ?? [],
    [gameModeId, matchlists, steamId],
  );
  const length = matchlist.length;
  const { page, isEndOfMatchList } = useMemo(() => {
    const page = Math.max(1, Math.ceil(length / DEFAULT_MATCHES_LIMIT));
    const isEndOfMatchList = length < DEFAULT_MATCHES_LIMIT * page;
    return {
      page,
      isEndOfMatchList,
    };
  }, [length]);

  const loadMoreMatches = () => {
    updateRoute(currentPath, searchParams, {
      page,
      isUpdate: false,
    });
  };

  const lastNStats = useCsLastN({ steamId: steamId, gameModeId });

  return (
    <>
      <ProfileColumn className="sidebar ad-align">
        <ProfileRanks profileId={steamId} />
        <MapStats profileId={steamId} gameModeId={gameModeId} />
        <WeaponStats profileId={steamId} gameModeId={gameModeId} />
      </ProfileColumn>
      <ProfileColumn className="main">
        <MatchHistoryHeader lastNStats={lastNStats} />
        <ProfileMatchlist>
          {length ? (
            <>
              <MatchList matchList={matchlist}>
                {matchlist.map((match) => (
                  <MatchTile
                    height={128}
                    id={match.matchId}
                    key={match.matchId}
                    match={matches[match.matchId]}
                  >
                    <MatchTileContent
                      match={matches[match.matchId]}
                      profileId={steamId}
                    />
                  </MatchTile>
                ))}
              </MatchList>
              <ShowMoreFooter
                hasMore={!isEndOfMatchList}
                setShowMore={loadMoreMatches}
              />
            </>
          ) : (
            <Card className="flex align-center column gap-6">
              <SkeletonMatchIcon style={{ fill: "transparent" }} />
              <div className="flex align-center column">
                <div className="type-h5">
                  {t("csgo:suggestions.noMatchups", "No Match Found")}
                </div>
                <div className="type-body1 shade1">
                  {t(
                    "csgo:suggestions.playOneGame",
                    "You should play at least one match to get match history feed.",
                  )}
                </div>
              </div>
            </Card>
          )}
        </ProfileMatchlist>
      </ProfileColumn>
    </>
  );
}

const PROFILE_QUEUES = [GAME_MODES["0_1_24"]];

function ProfileRanks({ profileId }) {
  const { t } = useTranslation();
  const {
    csgo: { profiles, playerStats },
  } = useSnapshot(readState);
  const profile = profiles?.[profileId];
  const stats = playerStats[profileId];

  return (
    <>
      {PROFILE_QUEUES.map((queue) => {
        const profileRank = profile?.[queue.rankName];
        const rank =
          queue?.rankName &&
          RANKS[queue.key?.[0] || GAME_MODE_RANKS.COMPETITIVE]?.[
            profileRank || 0
          ];
        const queueStats = stats?.[queue.id] || {};
        const wins = queueStats.matchWins;
        const losses = queueStats.matchLost;

        return (
          <ProfileRank
            key={queue.key}
            title={t([queue.t, queue.label])}
            name={rank?.name}
            image={rank?.icon && getRankIcon(rank.icon)}
            imageHeight={32}
            imageComponent={!rank?.icon && <CsgoUnranked />}
            color={getCsgoColor(0, profileRank)?.text}
            wins={wins}
            losses={losses}
            percentFill={wins / (wins + losses || 1)}
          />
        );
      })}
    </>
  );
}

export function meta(info) {
  const userName = info[1];
  return {
    title: [
      "cs2:profile",
      "{{userName}}'s Match Stats – CS2 – Blitz CS2",
      { userName },
    ],
    description: ["cs2:description", "profile for {{userName}}", { userName }],
  };
}

export default ProfileOverview;
