import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import FilterModes from "@/game-csgo/FilterModes.jsx";
import FilterWeaponCategory from "@/game-csgo/FilterWeaponCategory.jsx";
import MapsTable from "@/game-csgo/MapsTable.jsx";
import ProfileOverview from "@/game-csgo/ProfileOverview.jsx";
import { getGameModeById, getProfileAvatar } from "@/game-csgo/utils.mjs";
import WeaponsTable from "@/game-csgo/WeaponsTable.jsx";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile, { UnknownPlayerHeader } from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import ProfileEmpty from "@/shared/ProfileEmpty.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const FilterSelectContainer = styled("div")`
  display: flex;
  gap: var(--sp-2);
`;

function Profile(props) {
  const {
    searchParams,
    parameters: [profileId = props.profileId, tab],
    currentPath,
  } = useRoute();
  const { t } = useTranslation();
  const state = useSnapshot(readState);

  const profile = state.csgo?.profiles?.[profileId];
  const profileError = profile instanceof Error ? profile : null;

  const baseUrl = `/cs2/profile/${profileId}`;

  const tabs = useMemo(() => {
    return [
      {
        name: `${t("csgo:navigation.overview", "Overview")}`,
        id: "overview",
        url: baseUrl,
        isNew: false,
      },
      {
        name: `${t("csgo:navigation.weapons", "Weapons")}`,
        id: "weapons",
        url: `${baseUrl}/weapons`,
        isNew: false,
      },
      {
        name: `${t("csgo:navigation.maps", "Maps")}`,
        id: "maps",
        url: `${baseUrl}/maps`,
        isNew: false,
      },
    ];
  }, [baseUrl, t]);

  const SelectedTab = useMemo(() => {
    let component;
    switch (tab) {
      case "weapons":
        component = WeaponsTable;
        break;
      case "maps":
        component = MapsTable;
        break;
      default:
        component = ProfileOverview;
    }
    return component;
  }, [tab]);

  const category = searchParams.get("category") || "all";
  const gameModeParam = searchParams.get("mode");
  const gameModeId = getGameModeById(gameModeParam).id;

  const updateMode = (mode) => {
    searchParams.set("mode", mode);
    updateRoute(currentPath, searchParams, {
      page: undefined,
      isUpdate: false,
    });
  };
  const updateCategory = (value) => {
    searchParams.set("category", value);
    updateRoute(currentPath, searchParams, {
      page: undefined,
      isUpdate: false,
    });
  };

  return (
    <ProfileLayout>
      {!profileError ? (
        <PageHeader
          imageRound
          image={getProfileAvatar()}
          title={profile?.username}
          links={tabs.map((tab) => ({
            url: tab.url,
            text: tab.name,
          }))}
        />
      ) : (
        <UnknownPlayerHeader />
      )}
      <Container>
        <FilterSelectContainer>
          <FilterModes selected={gameModeId} onChange={updateMode} />
          {tab === "weapons" ? (
            <FilterWeaponCategory
              selected={category}
              onChange={updateCategory}
            />
          ) : null}
        </FilterSelectContainer>
      </Container>
      <Container>
        <SharedProfile>
          {profile ? <SelectedTab /> : <ProfileEmpty />}
        </SharedProfile>
      </Container>
    </ProfileLayout>
  );
}

export function meta(info) {
  const profileId = info[0];
  const profile = readState.csgo.profiles[profileId];

  return {
    title: [
      `home:meta.cs2.profile.title`,
      `{{userName}}'s Match Stats – CS2 – Blitz Counter Strike 2`,
      { userName: profile?.username },
    ],
    description: [
      `home:meta.cs2.profile.description`,
      `profile for {{userName}}`,
      { userName: profile?.username },
    ],
  };
}

export default Profile;
