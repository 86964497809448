import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { kdaColorStyle } from "@/app/util.mjs";
import type { MatchesLastN } from "@/game-csgo/useCsLastN.js";
import {
  getAverageDamageColor,
  getHeadshotColor,
  getWinRateColor,
} from "@/game-csgo/utils.mjs";
import CSGOCounterTerroristIcon from "@/inline-assets/csgo-counter-terrorist-icon.webp";
import CSGOTerroristIcon from "@/inline-assets/csgo-terrorist-icon.webp";
import MatchHistoryHeader from "@/shared/MatchHistoryHeader.jsx";
import { formatToFixedNumber, formatToPercent } from "@/util/i18n-helper.mjs";

function MatchListHeader({ lastNStats }: { lastNStats: MatchesLastN }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    wins,
    losses,
    draws,
    matches,
    winRate,
    kda,
    hs,
    ctWinrate,
    tWinrate,
    adr,
  } = lastNStats;

  return (
    <MatchHistoryHeader
      padding={"var(--sp-5)"}
      statColumns={[
        {
          statColor: "white",
          stat: t(
            "common:winslossesdraws",
            "{{wins, number}}W - {{losses, number}}L - {{draws, number}}D",
            {
              wins: wins,
              losses: losses,
              draws: draws,
            },
          ),
          description: t("common:lastValue", "Last {{ value, number }}", {
            value: matches,
          }),
        },
        {
          statColor: getWinRateColor(winRate),
          stat: formatToPercent(language, winRate, { max: 0 }),

          description: t("common:stats.wr", "Winrate"),
        },
        {
          statColor: kdaColorStyle(kda),
          stat: formatToFixedNumber(language, kda, 2),
          description: t("common:stats.kda", "KDA"),
        },
        {
          statColor: getHeadshotColor(hs * 100),
          stat: formatToPercent(language, hs),
          description: t("common:stats.hsPercent", "HS %"),
        },
        {
          statColor: getAverageDamageColor(adr),
          stat: formatToFixedNumber(language, adr, 1),
          description: t("common:stats.adr", "ADR"),
        },
      ]}
      matchBarGraph={{
        won: wins,
        lost: losses,
        draw: draws,
      }}
      IconList={[
        {
          key: 1,
          iconUrl: CSGOCounterTerroristIcon,
          statColor: getWinRateColor(ctWinrate),
          stat: formatToPercent(language, ctWinrate, {
            max: 0,
          }),
        },
        {
          key: 2,
          iconUrl: CSGOTerroristIcon,
          statColor: getWinRateColor(tWinrate),
          stat: formatToPercent(language, tWinrate, { max: 0 }),
        },
      ]}
      iconCount={2}
    />
  );
}

export default memo(MatchListHeader);
