import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import type { PlayerWeaponStats } from "@/data-models/csgo-player-weapon-stats.mjs";
import { WEAPONS as DATABASE_WEAPONS } from "@/game-cs2-marketing/constants.mjs";
import { WeaponCell } from "@/game-csgo/Common.style.jsx";
import { WEAPONS } from "@/game-csgo/constants.mjs";
import useCsgoWeapons from "@/game-csgo/useCsgoWeapons.jsx";
import { getGameModeById, getWeaponIcon } from "@/game-csgo/utils.mjs";
import DataTable from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import {
  formatToFixedNumber,
  formatToPercent,
  getLocale,
} from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

const Weapon = styled(WeaponCell)`
  justify-content: left;
`;

const WeaponsTable = () => {
  const { t } = useTranslation();
  const {
    parameters: [profileId],
    searchParams,
  } = useRoute();

  const cols = [
    {
      display: t("common:weapon", "Weapon"),
      align: "left",
      primary: true,
    },
    {
      display: t("common:stats.totalKills", "Total Kills"),
      align: "center",
      isStat: true,
    },
    {
      display: t("common:hsk", "HSK"),
      tooltip: t("common:headshotKills", "Headshot Kills"),
      align: "center",
      isStat: true,
    },
    {
      display: t("common:stats.hsPercent", "HS %"),
      align: "center",
      isStat: true,
    },
  ];

  const category = searchParams.get("category") || "all";
  const gameModeId = getGameModeById(searchParams.get("mode")).id;

  const { weapons }: { weapons: PlayerWeaponStats[] } = useCsgoWeapons({
    profileId,
    gameModeId,
  });

  const rows = useMemo(
    () =>
      weapons
        .filter(
          (weapon) =>
            category === "all" ||
            category === WEAPONS[weapon.weaponId].category,
        )
        .map((weapon) => {
          return [
            {
              display: (
                <Weapon>
                  <img src={getWeaponIcon(WEAPONS[weapon.weaponId]?.icon)} />
                  <span>
                    {t(
                      WEAPONS[weapon.weaponId]?.i18nKey,
                      WEAPONS[weapon.weaponId]?.name,
                    )}
                  </span>
                </Weapon>
              ),
              value: WEAPONS[weapon.weaponId].name,
              link: Object.keys(DATABASE_WEAPONS).includes(
                String(weapon.weaponId),
              )
                ? `/cs2/database/weapons/${
                    DATABASE_WEAPONS[weapon.weaponId].key
                  }/overview`
                : null,
            },
            {
              display: formatToFixedNumber(getLocale(), weapon.kills),
              value: weapon.kills,
            },
            {
              display: formatToFixedNumber(getLocale(), weapon.hsKills),
              value: weapon.hsKills,
            },
            {
              display: formatToPercent(
                getLocale(),
                weapon.hsKills / weapon.kills,
              ),
              value: weapon.hsKills / weapon.kills,
            },
          ];
        }),
    [weapons, category, t],
  );

  return (
    <PageContainer>
      <DataTable
        cols={cols}
        rows={rows}
        sortDir="DESC"
        sortCol={1}
        sortColTiebreak={2}
        indexCol
        noResultsDescription={[
          "csgo:weapons.noWeaponStatsFound",
          "There are currently no statistics available for the weapons page.",
        ]}
        noResultsReturnLink={`/cs2/profile/${profileId}`}
        noResultsReturnText={["common:goBackToOverview", "Go Back to Overview"]}
      />
    </PageContainer>
  );
};

export default WeaponsTable;
